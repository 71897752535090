<template v-if="formActive">
    <div class="wrapper" v-if="item.job_booking_voyage && item.job_booking_voyage.length == 0">{{ $t('errors.no_units_for_voyage') }}</div>
    <div class="wrapper" v-else>
        <div class="row unit-row fw-bold">
            <div class="col-auto col-form-label-sm" style="width: 8%">
                {{ $t('navigation.jobs') }}
            </div>
            <div class="col-auto col-form-label-sm" style="width: 12%">
                {{ $t('navigation.customers') }}
            </div>
            <div class="col-auto col-form-label-sm" style="width: 10%">
                {{ $t('navigation.units') }}
            </div>
            <div class="col-auto col-form-label-sm" style="width: 16%">
                CBM
            </div>
            <div class="col-auto col-form-label-sm" style="width: 14%">
                {{ $t('form.weight') }}
            </div>
            <div class="col-auto col-form-label-sm" style="width: 18%">
                {{ $t('operations.freight') }}
            </div>
            <div class="col-auto col-form-label-sm" style="width: 14.5%">
                {{ $t('navigation.surcharges') }}
            </div>
        </div>
        <template v-for="voyage in item.job_booking_voyage" :key="voyage.id">
            <div class="row unit-row" v-for="(unit, index) in voyage.job_booking_voyage_job_units" :key="unit.id">
                <div class="col-auto" style="width: 8%">
                    <div class="overflow-ellipsis">{{ voyage.job_booking ? voyage.job_booking.job.number : '-' }}</div>
                    <div class="overflow-ellipsis">{{ unit.job_unit.bill_of_lading ? unit.job_unit.bill_of_lading.number : '-' }}</div>
                </div>
                <div class="col-auto overflow-ellipsis" style="width: 12%">
                    {{ voyage.job_booking && voyage.job_booking.job.customer.relation ? voyage.job_booking.job.customer.relation.name : '' }}
                </div>
                <div class="col-auto" style="width: 10%">
                    <div class="overflow-ellipsis">{{ unit.job_unit.make && unit.job_unit.model ? (unit.job_unit.make.name + '&nbsp;' + unit.job_unit.model.name) : '-'  }}</div>
                    <div class="overflow-ellipsis">{{ unit.job_unit.chassis_number  }}</div>
                </div>
                <div class="col-auto" style="width: 16%">
                    <div class="d-flex cbm-row">
                        <div>
                            <input type="radio" class="form-check-radio" :id="'booked-cbm-'+unit.id" :name="'cbm-unit-'+unit.id" :value="1" v-model="unit.job_unit.volume_unit_measurements_id" @change="sendMeasurements(unit.job_unit, 'volume')" />
                            <label :for="'booked-cbm-'+unit.id">Booked</label>
                        </div>
                        <div>
                            {{ unit.job_unit.volume ? (getVolumeRounded(unit.job_unit.booked_measurement) ) : '-' }} CBM&ensp;<i class="fa fa-circle-info"></i><pre class="remark-blurb tx-1">{{getMeasurements(unit.job_unit.booked_measurement)}}</pre>
                        </div>
                    </div>
                    <div class="d-flex cbm-row">
                        <div>
                            <input type="radio" class="form-check-radio" :id="'measured-cbm-'+unit.id" :name="'cbm-unit-'+unit.id" :value="2" v-model="unit.job_unit.volume_unit_measurements_id" @change="sendMeasurements(unit.job_unit, 'volume')" />
                            <label :for="'measured-cbm-'+unit.id">Measured</label>
                        </div>
                        <div>
                            {{ unit.job_unit.volume ? (getVolumeRounded(unit.measured_measurement) ) : '-' }} CBM&ensp;<i class="fa fa-circle-info"></i><pre class="remark-blurb tx-1">{{getMeasurements(unit.measured_measurement)}}</pre>
                        </div>
                    </div>
                </div>
                <div class="col-auto" style="width: 14%">
                    <div class="d-flex weight-row">
                        <div>
                            <input type="radio" class="form-check-radio" :id="'booked-wei-'+unit.id" :name="'weight-unit-'+unit.id" :value="1" v-model="unit.job_unit.weight_unit_measurements_id" @change="sendMeasurements(unit.job_unit, 'weight')" />
                            <label :for="'booked-wei-'+unit.id">Booked</label>
                        </div>
                        <div>
                            {{ unit.job_unit.booked_measurement ? formatNumber(unit.job_unit.booked_measurement.weight) : '-'  }} kg
                        </div>
                    </div>
                    <div class="d-flex weight-row">
                        <div>
                            <input type="radio" class="form-check-radio" :id="'measured-wei-'+unit.id" :name="'weight-unit-'+unit.id" :value="2" v-model="unit.job_unit.weight_unit_measurements_id" @change="sendMeasurements(unit.job_unit, 'weight')" />
                            <label :for="'measured-wei-'+unit.id">Measured</label>
                        </div>
                        <div>
                            {{ unit.measured_measurement ? formatNumber(unit.measured_measurement.weight) : '-'  }} kg
                        </div>
                    </div>
                </div>
                <div class="col-auto" style="width: 18%">
                    <FormItem :id="'unit_'+unit.id" type="currency-and-unit" v-model="voyage.job_booking_voyage_job_units[index]" @onchange="update(unit, false)"
                              :options="currencyAndUnitOptions" inputGroupType="freight_purchase_rate" sizeItem="12" :calcUnitFields="calcUnitFields" />
                </div>
                <div class="col-auto surcharge-list" style="width: 21.8%">
                    <div v-for="(surcharge, sIndex) in unit.job_booking_voyage_job_unit_surcharge.filter(s => !s.deleted && s.id)" :key="sIndex" class="surch-row">
                        <div class="d-flex">
                            <FormItem v-model="surcharge.surcharge_type_id" type="select" :options="surchargeTypes" :extraOption="unit" nameField="code" :disabledOptions="checkIfAlreadyChosen" 
                                     @onchange="update(unit)" style="width: 90%" />
                            <button class="btn mini-button bg-danger" style="padding: 0 9px 0 8px; margin-left: 6px" type="button" @click="removeSurcharge(unit, sIndex)">
                                <i class="fa fa-close text-white"></i>
                            </button>
                        </div>
                        <div class="d-flex pt-2">
                            <FormItem v-model="unit.job_booking_voyage_job_unit_surcharge[sIndex]" inputGroupType="purchase_rate" sizeItem="12" :calcUnitFields="calcUnitFields" style="width: 90%"
                                     :id="'u_'+unit.id + '_'+sIndex" type="currency-and-unit" :options="currencyAndUnitOptions" @onchange="update(unit)" />
                            <button class="btn mini-button bg-success ms-2 px-2" type="button" :disabled="loading" @click="addSurcharge(unit)" v-if="unit.job_booking_voyage_job_unit_surcharge.filter(s => !s.deleted).length < surchargeTypeAmount">
                                <i class="fa fa-plus text-white"></i>
                            </button>
                        </div>
                    </div>
                    <button class="btn mini-button bg-success px-2 float-end" type="button" :disabled="loading" @click="addSurcharge(unit)" v-if="unit.job_booking_voyage_job_unit_surcharge.filter(s => !s.deleted && s.id).length == 0">
                        <i class="fa fa-plus text-white"></i>
                    </button>
                </div>
                
            </div>
        </template>
    </div>
</template>

<script>
import freightlistService from '@/services/FreightlistService';
import currencyService from "@/services/CurrencyService";
import calculationunitService from "@/services/CalculationunitService";
import surchargetypeService from "@/services/SurchargetypeService"

export default {
    data() {
        return {
            item: {},
            surchargeTypes: [],
            currencyAndUnitOptions: { 'currencies': [], 'calculation_units': []},
            calcUnitFields: ['currency_id', 'calculation_unit_id'],
            surchargeTypeAmount: 0,
            submitted: false,
            loading: false,
            invalid: {},
            dataItemsLoaded: 0,
            maxDataItemsToload: 2,
            formActive: false
        }
    },
    methods: {
            active(value){
                this.formActive = value;
            },
        validate(){
            return true;
        },
        getData() {
            return this.item;
        },
        setData(data) {
            this.item = data
            console.log(data);
            this.dataItemsLoaded = 0;
            document.getElementById('detailsSpan').innerHTML = ' - ' + data.name + ', ' + (data.carrier ? data.carrier.relation.name : '');
            this.loadData();
        },
        reset() {
            this.loading = false;
        },
        update(unit, updateSurcharges = true){
            if(this.loading) return;
            this.loading = true;
            let item = {
                id: unit.id,
                freight_purchase_rate: unit.freight_purchase_rate,
                freight_purchase_rate_calculation_unit_id: unit.freight_purchase_rate_calculation_unit_id,
                freight_purchase_rate_currency_id: unit.freight_purchase_rate_currency_id,
                job_booking_voyage_job_unit_surcharge: updateSurcharges ? unit.job_booking_voyage_job_unit_surcharge : []
            };
            freightlistService.update(item).then((response) => {
                console.log('freightlist update', response.data);
                const size = unit.job_booking_voyage_job_unit_surcharge.length;
                for(let i = 0; i < size; i++){
                    if(!unit.job_booking_voyage_job_unit_surcharge[i].id){
                        unit.job_booking_voyage_job_unit_surcharge[i].id = response.data.job_booking_voyage_job_unit_surcharge[i].id;
                    }
                }
                this.loading = false;
            }).catch(error => {
                this.toastError(error)
                console.log('error', error)
                this.loading = false
            });
        },
        getCurrencies() {
            currencyService.index().then(response => {
                this.currencyAndUnitOptions.currencies = response.data;
                window.defaultCurrency = this.currencyAndUnitOptions.currencies.filter(currency => currency.is_default == 1)[0].id;
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded == this.maxDataItemsToload){
                    this.processData();
                }
            }).catch(error => {
                console.log('error', error)
            })
        },
        getCalculationunits() {
            calculationunitService.index().then(response => {
                this.currencyAndUnitOptions.calculation_units = response.data.filter(cu => cu.id < 6);
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded == this.maxDataItemsToload){
                    this.processData();
                }
            }).catch(error => {
                console.log('error', error)
            })
        },
        getSurchargeTypes(){
                surchargetypeService.index(null, null, 'surchargetype').then(response => {
                    this.surchargeTypes = response.data //todo
                    this.surchargeTypeAmount = response.data.length;
                }).catch(error => {
                    console.log('error', error)
                })
            },
        loadData () {
            this.getCurrencies()
            this.getCalculationunits()
            this.getSurchargeTypes()
        },
        processData(){
            this.item.job_booking_voyage.forEach(voyage => {
                voyage.job_booking_voyage_job_units.forEach(unit => {
                    if(unit.freight_purchase_rate_currency_id === null){
                        unit.freight_purchase_rate_currency_id = window.defaultCurrency;
                    }
                    if(unit.freight_purchase_rate_calculation_unit_id === null){
                        unit.freight_purchase_rate_calculation_unit_id = 1;
                    }
                });
            });
        },
        addSurcharge(unit){
            unit.job_booking_voyage_job_unit_surcharge.push({
                "job_booking_voyage_job_unit_id": unit.id,
                "surcharge_type_id": null,
                "commodity_id": unit.job_unit.commodity_id,
                "purchase_rate": '',
                "purchase_rate_currency_id": window.defaultCurrency,
                "purchase_rate_calculation_unit_id": 1,
                "deleted": false
            });
            this.update(unit);
        },
        removeSurcharge(unit, index){
            unit.job_booking_voyage_job_unit_surcharge[index].deleted = true;
            this.update(unit);
        },
        checkIfAlreadyChosen(item, value, unit){
            if(item.id == value) return false;
            let comms = [];
            unit.job_booking_voyage_job_unit_surcharge.forEach(tsc => {
                comms.push(tsc.surcharge_type_id);
            })
            if(comms.includes(item.id)){
                return true;
            }
            return false;
        },
        getVolumeRounded(rvolume){
            if(!rvolume) return '-';
            let volume = (rvolume.length * rvolume.width * rvolume.height) / 1000000;
            if(isNaN(volume)) return "-"; 
            return volume.toFixed(3).replace('.', ',');
        },
        getMeasurements(ms){
            if(!ms) return '-';
            return ms.width + ' x ' + ms.height + ' x ' + ms.length;
        },
        sendMeasurements(unit, type){
            freightlistService.updateMeasurements(unit.id, unit[type + '_unit_measurements_id'], type).then((response) => {
                console.log('measurements update', response.data);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error)
            });
        }
    }
}
</script>
<style scoped>
.row{
    margin: unset !important
}
.unit-row{
    font-size: .85rem;
    padding: 4px 0
}
.unit-row:not(:last-child){
    border-bottom: 1px solid #ddd
}
.unit-row > div:not(.mini-button-column){
    padding: 0 6px !important
}
.mini-button-column{
    padding: 0 0 0 4px !important
}
.overflow-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
.surcharge-list > div{
    margin-bottom: .25rem
}
.mini-button{
    padding: 2px 0;
    height: 28px
}
.float-bottom{
    position: absolute;
    bottom: 4px
}
.cbm-row > div:first-child{
    width: 40%
}
.cbm-row > div:last-child{
    width: 60%;
    text-align: end;
}
.weight-row > div:first-child{
    width: 50%
}
.weight-row > div:last-child{
    width: 50%;
    text-align: end;
}
input[type="radio"] + label{
    color: gray;
    padding-left: .5rem
}
.surch-row:not(:first-child){
    margin-top: 1.5rem
}
.surch-row:not(:last-child) > div > button.bg-success{
    visibility: hidden;
}
.remark-blurb{
    display: none;
    position: absolute;
    text-align: center;
    z-index: 1111;
    background-color: #333;
    color: white;
    border-radius: 3px;
    padding: 4px;
    max-width: 250px;
    font-size: 0.8rem;
    font-family: unset
}
i:hover + .remark-blurb{
    display: block !important;
}
.tx-1{
    translate: calc(120px - 50%) -50px
}
</style>
<style>
#form-Freight-list .input-group select {
    width: 40px !important;
    border: 1px solid #888;
    background-color: #ddd;
    font-size: .85rem;
}
#form-Freight-list .input-group input {
    width: 40px !important;
    border-left: 1px solid #888;
    border-right: 1px solid #888
}
</style>